import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import './index.css';

// Import pages and loaders
import Root from "./routes/Root";
import HomePage from './pages/HomePage';

// Import error handlers
import DefaultError from "./pages/errors/DefaultError";


const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <DefaultError />,
        children: [
            { index: true, element: <HomePage /> },
        ],
    },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
