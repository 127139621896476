// src/pages/HomePage.js
import { useState, React } from 'react';
import SearchBar from '../components/Frontpage/SearchBar';

// Stocks in S&P500
const stocks = [
    { id: '1', name: 'Apple', ticker: 'AAPL' },
    { id: '2', name: 'Amazon', ticker: 'AMZN' },
];



const HomePage = () => {

    const [results, setResults] = useState();
    const [selectedProfile, setSelectedProfile] = useState();

    const handleChange = (e) => {
        const { target } = e;
        if (!target.value.trim()) return setResults([]);

        const filteredValues = stocks.filter((stock) =>
            stock.name.toLowerCase().startsWith(target.value.toLowerCase()) || stock.ticker.toLowerCase().startsWith(target.value.toLowerCase())
        );
        setResults(filteredValues);
    };



    return (
        <div className="flex min-h-screen flex-col items-center justify-center bg-gray-900/25 px-16">
            <h1 className="text-xl font-medium italic max-w-xl">
                This area will be used for adding text about a possible Patreon later on.
                We will want to do this for possibly getting profits in the future.
                We can also look into adding Advertisements later on at the bottom of this main screen.
            </h1>
            <SearchBar
                results={results}
                value={selectedProfile?.name}
                renderItem={(item) => <p>{item.name}</p>}
                onChange={handleChange}
                onSelect={(item) => setSelectedProfile(item)}
            />

        </div>
    );
};

export default HomePage;
