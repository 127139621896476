import { Outlet } from "react-router-dom";

//import "../scss/custom.scss";
//import "bootstrap/dist/js/bootstrap.bundle.min";

//import styles from "../css/root.module.css";

const Root = () => {
    return (
        <div>

            <div className="flex-1">
                <Outlet />
            </div>

        </div>
    );
};

export default Root;
