import { useCallback, useEffect, useRef, useState } from 'react';



const SearchBar = ({
    results = [], renderItem, value, onChange, onSelect,
}) => {
    const [focusedIndex, setFocusedIndex] = useState(-1);
    const resultContainer = useRef(null);
    const [showResults, setShowResults] = useState(false);
    const [defaultValue, setDefaultValue] = useState("");

    const handleSelection = (selectedIndex) => {
        const selectedItem = results[selectedIndex];
        if (!selectedItem) return resetSearchComplete();
        onSelect && onSelect(selectedItem);
        resetSearchComplete();
    };

    const resetSearchComplete = useCallback(() => {
        setFocusedIndex(-1);
        setShowResults(false);
    }, []);

    const handleKeyDown = (e) => {
        const { key } = e;
        let nextIndexCount = 0;

        // move down
        if (key === "ArrowDown")
            nextIndexCount = (focusedIndex + 1) % results.length;

        // move up
        if (key === "ArrowUp")
            nextIndexCount = (focusedIndex + results.length - 1) % results.length;

        // hide search results
        if (key === "Escape") {
            resetSearchComplete();
        }

        // select the current item
        if (key === "Enter") {
            e.preventDefault();
            handleSelection(focusedIndex);
        }

        setFocusedIndex(nextIndexCount);
    };

    const handleChange = (e) => {
        setDefaultValue(e.target.value);
        onChange && onChange(e);
    };

    useEffect(() => {
        if (!resultContainer.current) return;

        resultContainer.current.scrollIntoView({
            block: "center",
        });
    }, [focusedIndex]);

    useEffect(() => {
        if (results.length > 0 && !showResults) setShowResults(true);

        if (results.length <= 0) setShowResults(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [results]);

    useEffect(() => {
        if (value) setDefaultValue(value);
    }, [value]);

    return (
        <div
            className="relative w-full max-w-lg text-center"
            style={{ "marginTop": 33 + "%", "marginBottom": 33 + "%" }}

        >

            <div className="absolute -left-4 top-0 h-72 w-72 animate-blob rounded-full bg-purple-300 opacity-70 mix-blend-multiply blur-xl filter"></div>
            <div className="animation-delay-2000 absolute -right-4 top-0 h-72 w-72 animate-blob rounded-full bg-yellow-300 opacity-70 mix-blend-multiply blur-xl filter"></div>
            <div className="animation-delay-4000 absolute -bottom-8 left-20 h-72 w-72 animate-blob rounded-full bg-pink-300 opacity-70 mix-blend-multiply blur-xl filter"></div>
            <div className="relative m-8 space-y-4">
                <h2 className="text-4xl font-extrabold leading-tight tracking-tight text-gray-900/60">Ticker Earnings Lookup</h2>
                <div className="mx-auto max-w-md">
                    <div className="relative flex h-12 w-full items-center overflow-hidden rounded-lg bg-white focus-within:shadow-lg">
                        <div className="grid h-full w-12 place-items-center text-gray-300">
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                            </svg>
                        </div>
                        {/* Search Bar */}

                        <input
                            className="peer h-full w-full pr-2 text-sm text-gray-700 outline-none"
                            type="text"
                            id="search"
                            placeholder="Search Ticker..."
                            value={defaultValue}
                            onChange={handleChange}
                            tabIndex={1}
                            onBlur={resetSearchComplete}
                            onKeyDown={handleKeyDown}
                        />


                    </div>
                    {/* Search Results Container */}
                    {showResults && (
                        <div className="absolute mt-1 w-full p-2 bg-white shadow-lg rounded-bl rounded-br max-h-56 overflow-y-auto">
                            {results.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        onMouseDown={() => handleSelection(index)}
                                        ref={index === focusedIndex ? resultContainer : null}
                                        style={{
                                            backgroundColor:
                                                index === focusedIndex ? "rgba(0,0,0,0.1)" : "",
                                        }}
                                        className="cursor-pointer hover:bg-black hover:bg-opacity-10 p-2"
                                    >
                                        {renderItem(item)}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchBar;